(function(e, t) {
    function n() {
        var e = ["android", "webos", "iphone", "ipad", "blackberry", "Android", "webos", , "iPod", "iPhone", "iPad", "Blackberry", "BlackBerry"];
        var t = false;
        for (i in e) {
            if (navigator.userAgent.split(e[i]).length > 1) t = true
        }
        return t
    }

    function r(n) {
        n.find(".show_on_hover, .hovercover").each(function() {
            var n = e(this).closest("li");
            TweenLite.to(e(this), 0, {
                opacity: 0,
                overwrite: "all",
                ease: Power3.easeInOut
            });
            n.hover(function() {
                e(this).find(".show_on_hover, .hovercover").each(function() {
                    var n = 1;
                    if (e(this).data("maxopacity") != t) n = e(this).data("maxopacity");
                    TweenLite.to(e(this), 0, {
                        opacity: n,
                        overwrite: "all",
                        ease: Power3.easeInOut
                    })
                })
            }, function() {
                e(this).find(".show_on_hover, .hovercover").each(function() {
                    TweenLite.to(e(this), 0, {
                        opacity: 0,
                        ease: Power3.easeInOut
                    })
                })
            })
        })
    }

    function s(t, n) {
        t.find(".excerpt").each(function() {
            var t = e(this);
            t.closest("li").hover(function() {
                t.slideDown(300)
            }, function() {
                t.stop(true);
                t.slideUp(300)
            })
        });
        t.find(".closeme.inside").click(function() {
            var e = t.find(".reveal_container.revactive");
            if (e.hasClass("revactive")) {
                t.find(".revactive").removeClass("revactive");
                TweenLite.fromTo(e.find(".reveal_wrapper"), .3, {
                    visibility: "visible",
                    top: "0%",
                    height: "100%",
                    opacity: 1
                }, {
                    opacity: 0,
                    top: "0%",
                    height: "0%",
                    ease: Power3.easeInOut
                });
                e.find(".sb-vimeo-markup, .sb-yt-markup").html("");
                if (e.hasClass("tofullwidth")) {
                    h(200, t, n);
                    setTimeout(function() {
                        e.appendTo(e.data("comefrom"))
                    }, 350)
                }
            }
        });
        t.find(".reveal_opener").each(function() {
            var r = e(this);
            r.click(function() {
                if (r.parent().hasClass("reveal_container")) var i = r.parent();
                else var i = r.parent().find(".reveal_container");
                if (i.hasClass("revactive")) {
                    i.removeClass("revactive");
                    r.removeClass("revactive");
                    i.closest("li").removeClass("revactive");
                    TweenLite.fromTo(i.find(".reveal_wrapper"), .3, {
                        visibility: "visible",
                        top: "0%",
                        height: "100%",
                        opacity: 1
                    }, {
                        opacity: 0,
                        top: "0%",
                        height: "0%",
                        ease: Power3.easeInOut
                    });
                    i.find(".sb-vimeo-markup, .sb-yt-markup").html("");
                    if (i.hasClass("tofullwidth")) {
                        h(200, t, n);
                        setTimeout(function() {
                            i.appendTo(i.data("comefrom"))
                        }, 350)
                    }
                } else {
                    if (i.hasClass("tofullwidth")) {
                        i.data("comefrom", i.parent());
                        i.data("indexli", i.closest("li").index());
                        i.appendTo(i.closest(".showbiz"));
                        r.addClass("revactive")
                    }
                    setTimeout(function() {
                        if (t.data("coo") == "on") i.closest("ul").find(".reveal_opener").each(function(t) {
                            if (e(this).hasClass("revactive")) e(this).click()
                        });
                        i.addClass("revactive");
                        r.addClass("revactive");
                        i.closest("li").addClass("revactive");
                        TweenLite.fromTo(i.find(".reveal_wrapper"), .3, {
                            visibility: "visible",
                            height: "0%",
                            top: "0%",
                            opacity: 0
                        }, {
                            visibility: "visible",
                            top: "0%",
                            height: "100%",
                            opacity: 1,
                            ease: Power3.easeInOut
                        });
                        i.find(".sb-vimeo-markup, .sb-yt-markup").each(function(n) {
                            var r = e(this);
                            if (r.hasClass("sb-vimeo-markup")) var i = t.data("vimeomarkup");
                            else var i = t.data("ytmarkup");
                            var s = i.split("%%videoid%%")[0];
                            var o = i.split("%%videoid%%")[1];
                            var i = s + r.data("videoid") + o;
                            r.append(i);
                            try {
                                r.fitVids()
                            } catch (u) {}
                        });
                        setTimeout(function() {
                            o(t, n)
                        }, 500)
                    }, 100)
                }
            })
        })
    }

    function o(e, n) {
        var r = e.find(".tofullwidth.revactive .heightadjuster");
        var i = n.find("ul").first();
        var s = parseInt(r.parent().css("paddingTop"), 0) + parseInt(r.parent().css("paddingBottom"), 0);
        var o = 0;
        if (e.data("hboffset") != t) o = e.data("hboffset");
        var u = o + s + r.outerHeight(true);
        TweenLite.to(i, .3, {
            height: u + "px",
            ease: Power3.easeInOut
        });
        TweenLite.to(i.parent(), .3, {
            height: u + "px",
            ease: Power3.easeInOut
        });
        var a = e.find(".showbiz-navigation").outerHeight(true);
        TweenLite.to(e.closest(".forcefullwidth_wrapper_tp_banner"), .3, {
            height: u + a + "px",
            ease: Power3.easeInOut
        })
    }

    function u(t, n, r) {
        var i = t.data("carousel");
        var s = t.data("speedy");
        if (t.find(".tofullwidth.revactive .heightadjuster").length > 0) {
            var o = t.find(".tofullwidth.revactive").data("indexli");
            var u = o;
            if (u <= 0) u = t.find("ul:first-child li").length;
            t.find(".tofullwidth.revactive").addClass("sb-removemesoon");
            setTimeout(function() {
                t.find(".tofullwidth.revactive.sb-removemesoon .reveal_opener").click();
                t.find(".sb-removemesoon").each(function() {
                    e(this).removeClass("sb-removemesoon")
                })
            }, 350);
            t.find("ul:first-child li:nth-child(" + u + ")").find(".reveal_opener").click()
        } else {
            var a = n.data("teaser");
            var f = a.find("ul").first();
            if (t.data("das") == "on") {
                c(t, 1, r)
            } else {
                var l = t.data("currentoffset");
                var p;
                var d = t.width();
                if (t.data("allentry") == "on") {
                    if (d > 980) {
                        p = t.data("vea")[0]
                    }
                    if (d < 981 && d > 768) {
                        p = t.data("vea")[1]
                    }
                    if (d < 769 && d > 420) {
                        p = t.data("vea")[2]
                    }
                    if (d < 421) {
                        p = t.data("vea")[3]
                    }
                } else {
                    p = 1
                }
                if (maxitem >= p) l = l - p;
                else l = 0;
                t.data("currentoffset", l);
                h(s, t, a)
            }
        }
    }

    function a(t, n, r) {
        var i = t.data("carousel");
        var s = t.data("speedy");
        if (t.find(".tofullwidth.revactive .heightadjuster").length > 0) {
            var o = t.find(".tofullwidth.revactive").data("indexli");
            var u = o + 2;
            if (u > t.find("ul:first-child li").length) u = 1;
            t.find(".tofullwidth.revactive").addClass("sb-removemesoon");
            setTimeout(function() {
                t.find(".tofullwidth.revactive.sb-removemesoon .reveal_opener").click();
                t.find(".sb-removemesoon").each(function() {
                    e(this).removeClass("sb-removemesoon")
                })
            }, 350);
            t.find("ul:first-child li:nth-child(" + u + ")").find(".reveal_opener").click()
        } else {
            var a = e(n.data("teaser"));
            var f = a.find("ul").first();
            var l = f.find(">li").length;
            if (t.data("das") == "on") {
                c(t, -1, r)
            } else {
                var p = t.data("currentoffset");
                var d;
                var v = t.width();
                if (t.data("allentry") == "on") {
                    if (v > 980) {
                        d = t.data("vea")[0]
                    }
                    if (v < 981 && v > 768) {
                        d = t.data("vea")[1]
                    }
                    if (v < 769 && v > 420) {
                        d = t.data("vea")[2]
                    }
                    if (v < 421) {
                        d = t.data("vea")[3]
                    }
                } else {
                    d = 1
                }
                if (l >= d) p = p + d;
                else p = 0;
                t.data("currentoffset", p);
                h(s, t, a)
            }
        }
    }

    function f(n) {
        var r = 0;
        n.find("img").each(function() {
            var n = e(this);
            var i = n.data("lazyloadsrc");
            if (i != t && i.length > 3 && !n.hasClass("waitingforload") && r < 4) {
                n.addClass("waitingforload");
                n.closest(".mediaholder").append('<div class="sb-preloader"></div>');
                var s = n;
                if (s.data("wan") == t) s.data("wan", s.css("-webkit-transition"));
                if (s.data("moan") == t) s.data("moan", s.css("-moz-animation-transition"));
                if (s.data("man") == t) s.data("man", s.css("-ms-animation-transition"));
                if (s.data("ani") == t) s.data("ani", s.css("transition"));
                s.css("-webkit-transition", "none");
                s.css("-moz-transition", "none");
                s.css("-ms-transition", "none");
                s.css("transition", "none");
                var o = new Image;
                o.onload = function() {
                    n.attr("src", i);
                    TweenLite.set(n, {
                        height: "auto",
                        overwrite: "auto"
                    });
                    TweenLite.fromTo(n, .2, {
                        autoAlpha: 0
                    }, {
                        autoAlpha: 1,
                        overwrite: "auto"
                    });
                    n.removeClass("waitingforload");
                    TweenLite.to(n.closest(".mediaholder").find(".sb-preloader"), .3, {
                        autoAlpha: 0,
                        onComplete: function() {
                            n.closest(".mediaholder").find(".sb-preloader").remove()
                        }
                    });
                    setTimeout(function() {
                        s.css("-webkit-transition", s.data("wan"));
                        s.css("-moz-transition", s.data("moan"));
                        s.css("-ms-transition", s.data("man"));
                        s.css("transition", s.data("ani"))
                    }, 300)
                };
                o.src = i;
                n.data("lazyloadsrc", "");
                r++
            } else {
                if (n.hasClass("waitingforload")) r++;
                if (i != t && i.length > 3) {
                    if (n.data("lazyloadheight") != t) n.css({
                        height: parseInt(n.data("lazyloadheight"), 0)
                    })
                }
            }
        });
        if (r != 0) setTimeout(function() {
            f(n)
        }, 50);
        else e("body").trigger("resize")
    }

    function l(n, r) {
        var i = n.data("carousel");
        n.find(".rating-star").each(function() {
            var n = e(this);
            if (n.data("rates") != t) {
                var r = n.data("rates");
                n.append('<div class="sb-rateholder"></div>');
                for (var i = 1; i < 6; i++) {
                    var s = 100;
                    if (r == 0) {
                        s = 0
                    } else {
                        if (r >= i) s = 100;
                        else {
                            s = (r - Math.floor(r)) * 100;
                            if (i - r > 1) s = 0
                        }
                    }
                    n.find(".sb-rateholder").append('<div class="sb-rateholder-single"><div style="width:' + s + '%;overflow:hidden"><i class="sb-icon-star"></i></div><i class="sb-icon-star-empty"></i></div>')
                }
                n.find(".sb-rateholder").append('<div class="sb-clear"></div>')
            }
        });
        f(n);
        var s = e(r.data("left"));
        var o = e(r.data("right"));
        var l = n.width();
        s.data("teaser", r);
        o.data("teaser", r);
        r.data("offset", 0);
        h(0, n, r);
        n.find("img").each(function() {
            e(this).parent().waitForImages(function() {
                h(200, n, r)
            })
        });
        o.click(function() {
            a(n, o);
            return false
        });
        s.click(function() {
            u(n, s);
            return false
        });
        if (n.data("das") != "on") {
            n.hammer({
                swipe_velocity: .4
            });
            Hammer(n).on("swipeleft", function() {
                o.click()
            });
            Hammer(n).on("swiperight", function() {
                s.click()
            })
        }
        var p;
        e(window).resize(function() {
            clearTimeout(p);
            n.addClass("hovered");
            p = setTimeout(function() {
                if (n.data("forceFullWidth") == true) {
                    var t = n.closest(".forcefullwidth_wrapper_tp_banner").offset().left;
                    n.css({
                        left: 0 - t + "px",
                        width: e(window).width()
                    })
                }
                h(300, n, r);
                if (n.data("das") == "on") {
                    setTimeout(function() {
                        c(n, 0)
                    }, 300)
                }
                n.removeClass("hovered")
            }, 150)
        });
        for (var d = 0; d < 3; d++) {
            e(window).data("teaserreset", setTimeout(function() {
                h(200, n, r)
            }, d * 500))
        }
    }

    function c(t, n, r) {
        var i = t;
        var s = t.width();
        var o = i.find("ul").first();
        var u = o.find(">li").length;
        var a = 4;
        if (s > 980) a = t.data("vea")[0];
        if (s < 981 && s > 768) a = t.data("vea")[1];
        if (s < 769 && s > 420) a = t.data("vea")[2];
        if (s < 421) a = t.data("vea")[3];
        var f = u - a;
        var l = o.find(">li:first-child").outerWidth(true);
        var c = i.find(".overflowholder");
        var h = o.position().left;
        var p = Math.round(h / l);
        var d = e(c.parent().data("right"));
        var v = e(c.parent().data("left"));
        var m = (p + n) * l;
        if (Math.abs(m) >= f * l) {
            m = -(f * l);
            try {
                d.addClass("notclickable")
            } catch (g) {}
            if (t.hasClass("sb-attheend")) {
                try {
                    d.removeClass("notclickable")
                } catch (g) {}
                t.removeClass("sb-attheend");
                m = 0
            } else {
                if (r && i.data("rewindfromend") == "on") t.addClass("sb-attheend")
            }
        } else {
            t.removeClass("sb-attheend");
            try {
                d.removeClass("notclickable")
            } catch (g) {}
        }
        if (m >= 0) {
            m = 0;
            try {
                v.addClass("notclickable")
            } catch (g) {}
        } else {
            try {
                v.removeClass("notclickable")
            } catch (g) {}
        }
        TweenLite.to(o, .3, {
            left: m
        })
    }

    function h(n, r, i) {
        var s = r.data("carousel");
        var u = i.find("ul");
        var a = r.data("currentoffset");
        var f = r.width();
        var l = parseInt(i.css("paddingLeft"), 0) + parseInt(i.css("paddingRight"), 0);
        f = f - l;
        if (u.parents(":hidden").length != 0) return false;
        var u = i.find("ul:first");
        maxitem = u.find(">li").length;
        var c = e(i.data("right"));
        if (r.data("das") != "on") c.removeClass("notclickable");
        var h = e(i.data("left"));
        if (r.data("das") != "on") h.removeClass("notclickable");
        var d = r.data("vea")[0];
        var v = r.data("mediaMaxHeight");
        if (f > 980) {
            d = r.data("vea")[0];
            try {
                if (v[0] != 0) r.find(".mediaholder_innerwrap").each(function() {
                    e(this).css({
                        maxHeight: v[0] + "px"
                    })
                })
            } catch (m) {}
        }
        if (f < 981 && f > 768) {
            d = r.data("vea")[1];
            try {
                if (v[1] != 0) r.find(".mediaholder_innerwrap").each(function() {
                    e(this).css({
                        maxHeight: v[1] + "px"
                    })
                })
            } catch (m) {}
        }
        if (f < 769 && f > 420) {
            d = r.data("vea")[2];
            try {
                if (v[2] != 0) r.find(".mediaholder_innerwrap").each(function() {
                    e(this).css({
                        maxHeight: v[2] + "px"
                    })
                })
            } catch (m) {}
        }
        if (f < 421) {
            d = r.data("vea")[3];
            try {
                if (v[3] != 0) r.find(".mediaholder_innerwrap").each(function() {
                    e(this).css({
                        maxHeight: v[3] + "px"
                    })
                })
            } catch (m) {}
        }
        var g = u.find(">li:first-child").outerWidth(true) - u.find(">li:first-child").width();
        var y = 0;
        if (r.data("eoffset") != t) y = r.data("eoffset") * (d - 1);
        var b = 0;
        if (r.data("croffset") != t) b = r.data("croffset");
        step = (f - (d - 1) * g) / d;
        step = Math.round(step - y);
        var w = false;
        u.find(">li").each(function() {
            if (e(this).width() != step) w = true;
            e(this).width(step)
        });
        u.css({
            width: "40000px"
        });
        var E = 0;
        if (w) E = 450;
        var S = r.data("ease");
        setTimeout(function() {
            step = u.find("li:first").outerWidth(true);
            var e = step * a;
            if (e < 0) e = 0;
            var t = 0;
            var i = maxitem * step - f;
            var o = e + g;
            if (s != 1) {
                if (i + 3 <= o && a > 1) {
                    if (r.data("rewindfromend") == "on") {
                        if (i < o) {
                            e = 0;
                            r.data("currentoffset", 0)
                        }
                    } else {
                        r.data("currentoffset", maxitem - d);
                        e = step * (maxitem - d);
                        c.addClass("notclickable")
                    }
                }
                if (a <= 0) {
                    if (r.data("rewindfromend") == "on") {
                        if (a < 0) {
                            r.data("currentoffset", maxitem - d);
                            e = step * (maxitem - d)
                        }
                    } else {
                        e = 0;
                        r.data("currentoffset", 0);
                        h.addClass("notclickable")
                    }
                }
                if (p(8)) TweenLite.to(u, n / 1e3, {
                    left: 0 - e + "px",
                    transformPerspective: 300,
                    ease: S
                });
                else TweenLite.to(u, n / 1e3, {
                    x: 0 - e + "px",
                    transformPerspective: 300,
                    ease: S
                })
            } else {
                if (i <= o) {
                    a = a - 2;
                    r.data("currentoffset", a + 1);
                    e = step * a;
                    u.find(">li").first().appendTo(u);
                    if (p(8)) TweenLite.set(u, {
                        left: 0 - e + "px",
                        transformPerspective: 300,
                        ease: S
                    });
                    else TweenLite.set(u, {
                        x: 0 - e + "px",
                        transformPerspective: 300,
                        ease: S
                    });
                    a = a + 1;
                    e = step * a
                }
                if (a < 0) {
                    a = 1;
                    r.data("currentoffset", 0);
                    e = step * a;
                    u.find(">li").last().prependTo(u);
                    if (p(8)) TweenLite.set(u, {
                        left: 0 - e + "px",
                        transformPerspective: 300,
                        ease: S
                    });
                    else TweenLite.set(u, {
                        x: 0 - e + "px",
                        transformPerspective: 300,
                        ease: S
                    });
                    a = 0;
                    e = step * a
                }
                if (p(8)) TweenLite.to(u, n / 1e3, {
                    left: 0 - e + "px",
                    transformPerspective: 300,
                    ease: S
                });
                else TweenLite.to(u, n / 1e3, {
                    x: 0 - e + "px",
                    transformPerspective: 300,
                    ease: S
                })
            }
        }, E);
        var x = 0;
        if (r.data("hboffset") != t) x = r.data("hboffset");
        setTimeout(function() {
            var t = 0;
            u.find("li").each(function() {
                if (e(this).outerHeight(true) > t) t = e(this).outerHeight(true)
            });
            setTimeout(function() {
                if (step > 100) {
                    var e = u.find(">li:last-child");
                    var t = u.find(">li:nth(2)");
                    var n = (e.index() + 1) * e.outerWidth(true) + g;
                    u.css({
                        width: n + "px"
                    })
                }
            }, 200);
            if (r.find(".tofullwidth.revactive .heightadjuster").length > 0) {
                o(r, i)
            } else {
                if (u.parents(":hidden").length == 0) {
                    TweenLite.to(u, .3, {
                        height: t + x + "px",
                        ease: Power3.easeInOut
                    });
                    TweenLite.to(u.parent(), .3, {
                        height: t + x + "px",
                        ease: Power3.easeInOut
                    });
                    var n = r.find(".showbiz-navigation").outerHeight(true);
                    TweenLite.to(r.closest(".forcefullwidth_wrapper_tp_banner"), .3, {
                        height: t + x + n + "px",
                        ease: Power3.easeInOut
                    })
                }
            }
        }, n + 210)
    }

    function p(t, n) {
        var r = e('<div style="display:none;"/>').appendTo(e("body"));
        r.html("<!--[if " + (n || "") + " IE " + (t || "") + "]><a>&nbsp;</a><![endif]-->");
        var i = r.find("a").length;
        r.remove();
        return i
    }
    e.fn.extend({
        showbizpro: function(n) {
            e.fn.showbizpro.defaults = {
                entrySizeOffset: 0,
                containerOffsetRight: 0,
                heightOffsetBottom: 0,
                carousel: "off",
                visibleElementsArray: [4, 3, 2, 1],
                mediaMaxHeight: [0, 0, 0, 0],
                ytMarkup: "<iframe src='http://www.youtube.com/embed/%%videoid%%?hd=1&wmode=opaque&autohide=1&showinfo=0&autoplay=1'></iframe>",
                vimeoMarkup: "<iframe src='http://player.vimeo.com/video/%%videoid%%?title=0&byline=0&portrait=0;api=1&autoplay=1'></iframe>",
                closeOtherOverlays: "off",
                allEntryAtOnce: "off",
                dragAndScroll: "off",
                autoPlay: "off",
                delay: 3e3,
                speed: 300,
                rewindFromEnd: "off",
                easing: "Power3.easeOut",
                forceFullWidth: false,
                scrollOrientation: "left"
            };
            n = e.extend({}, e.fn.showbizpro.defaults, n);
            return this.each(function() {
                function C() {
                    i.data("timer", setInterval(function() {
                        if (i.width() > 0) {
                            if (i.data("scrollOrientation") == "right") {
                                var t = e(i.find(".showbiz").data("left"));
                                if (!i.hasClass("hovered")) u(i, t, true)
                            } else {
                                var t = e(i.find(".showbiz").data("right"));
                                if (!i.hasClass("hovered")) a(i, t, true)
                            }
                        }
                    }, n.delay))
                }

                function k() {
                    clearInterval(i.data("timer"))
                }
                var i = e(this);
                if (n.forceFullWidth == true) {
                    var o = i.offset().left;
                    var f = i.css("marginBottom");
                    var h = i.css("marginTop");
                    if (f == t) f = 0;
                    if (h == t) h = 0;
                    i.wrap('<div style="position:relative;width:100%;height:auto;margin-top:' + h + ";margin-bottom:" + f + '" class="forcefullwidth_wrapper_tp_banner"></div>');
                    i.closest(".forcefullwidth_wrapper_tp_banner").append('<div class="tp-fullwidth-forcer" style="width:100%;height:' + i.height() + 'px"></div>');
                    i.css({
                        maxWidth: "none",
                        left: 0 - o + "px",
                        position: "absolute",
                        width: e(window).width()
                    })
                }
                i.data("eoffset", n.entrySizeOffset);
                i.data("croffset", n.containerOffsetRight);
                i.data("hboffset", n.heightOffsetBottom);
                i.data("ease", n.easing);
                if (n.carousel == "on") i.data("carousel", 1);
                else i.data("carousel", 0);
                i.data("ytmarkup", n.ytMarkup);
                i.data("vimeomarkup", n.vimeoMarkup);
                i.data("vea", n.visibleElementsArray);
                i.data("coo", n.closeOtherOverlays);
                i.data("allentry", n.allEntryAtOnce);
                i.data("mediaMaxHeight", n.mediaMaxHeight);
                i.data("das", n.dragAndScroll);
                i.data("rewindfromend", n.rewindFromEnd);
                i.data("forceFullWidth", n.forceFullWidth);
                i.data("scrollOrientation", n.scrollOrientation);
                i.data("currentoffset", 0);
                n.speed = parseInt(n.speed, 0);
                n.delay = parseInt(n.delay, 0);
                i.data("speedy", n.speed);
                i.data("ie", !e.support.opacity);
                i.data("ie9", document.documentMode == 9);
                var p = e.fn.jquery.split("."),
                    d = parseFloat(p[0]),
                    v = parseFloat(p[1]),
                    m = parseFloat(p[2] || "0");
                if (d > 1) i.data("ie", false);
                if (n.carousel == "on") {
                    if (i.find("ul").first().find(">li").length < 17) {
                        i.find("ul").first().find(">li").each(function(t) {
                            e(this).clone(true).appendTo(i.find("ul").first())
                        })
                    }
                    if (i.find("ul").first().find(">li").length < 17) {
                        i.find("ul").first().find(">li").each(function(t) {
                            e(this).clone(true).appendTo(i.find("ul").first())
                        })
                    }
                }
                var g = i.find(".showbiz");
                g.attr("id", "sbiz" + Math.round(Math.random() * 1e4));
                var y;
                g.find("img").on("dragstart", function(e) {
                    e.preventDefault()
                });
                if (n.dragAndScroll == "on") {
                    var b = g.find(".overflowholder ul");

                    function w(e) {
                        var n = b.find("li").first();
                        var r = b.data("newpos");
                        if (r == t) {
                            r = b.position().left;
                            b.data("newpos", r)
                        }
                        e.gesture.preventDefault();
                        switch (e.type) {
                            case "dragstart":
                                b.data("newpos", b.position().left);
                                b.addClass("dragged");
                                break;
                            case "dragright":
                            case "dragleft":
                                if (r > 0) r = 0;
                                TweenLite.to(b, .1, {
                                    left: r + e.gesture.deltaX,
                                    ease: Power3.easeOut
                                });
                                break;
                            case "release":
                                b.removeClass("dragged");
                                c(i, 0);
                                break
                        }
                    }
                    var E = g.hammer();
                    E.on("dragright", w);
                    E.on("dragleft", w);
                    E.on("dragstart", w);
                    E.on("release", w);
                    b.addClass("showbiz-drag-mouse")
                }
                var S = Math.round(Math.random() * 1e5);
                if (g.data("left") == t) g.data("left", "sb_left_" + S);
                if (g.data("right") == t) g.data("right", "sb_right_" + S);
                if (g.data("play") == t) g.data("play", "sb_play_" + S);
                var x = e(g.data("left"));
                var T = e(g.data("right"));
                var N = e(g.data("play"));
                if (x == t || x.length == 0) e("body").append('<a style="display:none" id="' + g.data("left") + '" class="sb-navigation-left"><i class="sb-icon-left-open"></i></a>');
                if (T == t || T.length == 0) e("body").append('<a style="display:none" id="' + g.data("right") + '" class="sb-navigation-right"><i class="sb-icon-right-open"></i></a>');
                if (N == t || N.length == 0) e("body").append('<a style="display:none" id="' + g.data("play") + '" class="sb-navigation-play"><i class="sb-icon-play sb-playbutton"></i><i class="sb-icon-pause sb-pausebutton"></i></a>');
                l(i, g);
                if (n.autoPlay != "on") {
                    e(i.find(".showbiz").data("play")).remove()
                } else {
                    C();
                    var N = e(i.find(".showbiz").data("play"));
                    N.find(".sb-playbutton").addClass("sb-hidden");
                    i.hover(function() {
                        i.addClass("hovered")
                    }, function() {
                        i.removeClass("hovered")
                    });
                    N.click(function() {
                        if (N.hasClass("paused")) {
                            C();
                            N.removeClass("paused");
                            N.find(".sb-pausebutton").removeClass("sb-hidden");
                            N.find(".sb-playbutton").addClass("sb-hidden")
                        } else {
                            k();
                            N.addClass("paused");
                            N.find(".sb-pausebutton").addClass("sb-hidden");
                            N.find(".sb-playbutton").removeClass("sb-hidden")
                        }
                    })
                }
                s(i, g);
                try {
                    i.find(".mediaholder_innerwrap").each(function() {
                        var t = e(this);
                        if (t.find("iframe").length > 0) e(this).fitVids()
                    })
                } catch (L) {}
                r(i);
                if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/iPad/i)) {
                    e(".reveal_opener, .sb-navigation-left, .sb-navigation-right").click(function() {})
                }
            })
        },
        showbizredraw: function(t) {
            return this.each(function() {
                var t = e(this);
                var n = t.find(".showbiz");
                h(200, t, n)
            })
        }
    });
})(jQuery);
(function(e, t) {
    e.fn.fitVids = function(t) {
        var n = {
            customSelector: null
        };
        var r = document.createElement("div"),
            i = document.getElementsByTagName("base")[0] || document.getElementsByTagName("script")[0];
        r.className = "fit-vids-style";
        r.innerHTML = "­<style>               .fluid-width-video-wrapper {                 width: 100%;                              position: relative;                       padding: 0;                            }                                                                                   .fluid-width-video-wrapper iframe,        .fluid-width-video-wrapper object,        .fluid-width-video-wrapper embed {           position: absolute;                       top: 0;                                   left: 0;                                  width: 100%;                              height: 100%;                          }                                       </style>";
        i.parentNode.insertBefore(r, i);
        if (t) {
            e.extend(n, t)
        }
        return this.each(function() {
            var t = ["iframe[src*='player.vimeo.com']", "iframe[src*='www.youtube.com']", "iframe[src*='www.kickstarter.com']", "object", "embed"];
            if (n.customSelector) {
                t.push(n.customSelector)
            }
            var r = e(this).find(t.join(","));
            r.each(function() {
                var t = e(this);
                if (this.tagName.toLowerCase() == "embed" && t.parent("object").length || t.parent(".fluid-width-video-wrapper").length) {
                    return
                }
                var n = this.tagName.toLowerCase() == "object" ? t.attr("height") : t.height(),
                    r = n / t.width();
                if (!t.attr("id")) {
                    var i = "fitvid" + Math.floor(Math.random() * 999999);
                    t.attr("id", i)
                }
                t.wrap('<div class="fluid-width-video-wrapper"></div>').parent(".fluid-width-video-wrapper").css("padding-top", r * 100 + "%");
                t.removeAttr("height").removeAttr("width")
            })
        })
    }
})(jQuery);