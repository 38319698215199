$(document).ready(function(){

    setTimeout(function(){



        $('.goog-te-menu-frame').each(function(index,item){
            $(item).contents().find('td').attr('style', 'background: #212C39 !important');
            $(item).contents().find('div').attr('style', 'background-color: #212C39 !important; color:#8C949E !important; border: 1px solid #212C39 !important;');
            $(item).contents().find('table').attr('style', 'background: #212C39 !important');

        });

    }, 1000);

    $('#google_translate_element').click(function(){

        $('.goog-te-menu-frame').each(function(index,item){
            $(item).contents().find('td').attr('style', 'background: #212C39 !important');
            $(item).contents().find('div').attr('style', 'background-color: #212C39 !important; color:#8C949E !important; border: 1px solid #212C39 !important;');
            $(item).contents().find('table').attr('style', 'background: #212C39 !important');

        });
    });

});

